import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import { AudioPreviewComponent } from './audio-preview.component';

@NgModule({
  imports: [FormsModule, ReactiveFormsModule, TooltipModule, CommonModule],
  declarations: [AudioPreviewComponent],
  exports: [AudioPreviewComponent],
})
export class CtlsAudioPreviewModule {}
